<template>
  <div class="passage pb-4 bg-cent passage-t">
    <div class="main-title">
      <h1>快速咨询通道</h1>
    </div>
    <div class="passage-form px-6">
      <a-input-group class="mx-1" compact>
        <a-select style="width: 100%" v-model="type">
          <template v-for="(item, index) in passagetype">
            <a-select-option :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
      </a-input-group>
      <a-input
        class="mx-1"
        v-model="passageName"
        placeholder="请输入您需要办理的资质名称"
      />
      <a-input class="mx-1" v-model="username" placeholder="请输入您的姓名" />
      <a-input
        class="mx-1"
        onkeyup="value=value.replace(/[^\d]/g,'')"
        v-model="phone"
        placeholder="请输入您的手机号"
      />
      <a-button class="mx-1" type="primary" @click="setMessage">
        立即咨询
      </a-button>
    </div>
    <div class="passage-amount mt-3 px-4">
      <p>
        今日办理：<span class="text-color-f">{{pAmount.today }}</span> 件
      </p>
      <p>
        累计办理：<span class="text-color-f">{{pAmount.total }}</span> 件
      </p>
      <div class="history">
        <!-- <h3>办理记录：</h3> -->
        <div class="history-container swiper-container">
          <div class="swiper-wrapper">
            <template v-for="(item, index) in historyList">
              <div class="swiper-slide" :key="index">
					<span class="text-color mr-1">{{ item.split(' ')[0] }}</span>	
					<span class="text-color-f mr-1">{{ item.split(' ')[1] }}</span>	
					<span class="text-color mr-1">{{ item.split(' ')[2] }}</span>	
					<span class="text-color-f">{{ item.split(' ')[3] }}</span>	
			  </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="m-passage-form mt-3 px-4">
      <a-input-group compact>
        <a-select style="width: 100%" v-model="type">
          <template v-for="(item, index) in passagetype">
            <a-select-option :key="index" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </template>
        </a-select>
      </a-input-group>
      <a-input v-model="passageName" placeholder="请输入您需要办理的资质名称" />
      <a-input v-model="username" placeholder="请输入您的姓名" />
      <a-input
        onkeyup="value=value.replace(/[^\d]/g,'')"
        v-model="phone"
        placeholder="请输入您的手机号"
      />
      <a-button type="primary" @click="setMessage"> 立即咨询 </a-button>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/assets/js/formDate.js";
export default {
  data() {
    return {
      type: "请选择资质类型",
      passageName: "",
      username: "",
      phone: "",
      passagetype: [],
      pAmount: null,
      historyList: [
        "3个月前 139****0234用户 已成功办理 【建筑装修装饰工程资质】",
        "10个小时前 158****2216用户 已成功办理 【建筑工程总承包资质】",
        "21个小时前 146****81374用户 已成功办理 【市政工程总承包资质】",
        "1天前 176****9131用户 已成功办理 【建筑机电安装专业承包资质】",
        "3天前 188****6666用户 已成功办理 【消防工程专业承包资质】",
        "1个月前 136****1680用户 已成功办理 【机电总包资质】",
        "23小时前 133****1268用户 已成功办理 【环保工程资质】",
        "5天前 132****9852用户 已成功办理 【承装修试三级】",
        "10天前 180****7512用户 已成功办理 【环保设计资质】",
        "12天前 172****5124用户 已成功办理 【房建一级资质】",
        "10个小时前 138****8888用户 已成功办理 【建筑装修装饰工程资质】",
        "2天前 136****6266用户 已成功办理 【模板脚手架工程资质】",
        "6个小时前 136****4288用户 已成功办理 【钢结构工程资质】",
        "4天前 136****6836用户 已成功办理 【电子智能化工程资质】",
        "6天前 153****6661用户 已成功办理 【承装修试电力工程资质】",
        "4个小时前 151****0108用户 已成功办理 【建筑装修装饰工程资质】",
        "5个小时前 187****5222用户 已成功办理 【环保工程资质】",
      ],
    };
  },
  methods: {
    setMessage() {
      let source_url = window.location.href;
      if (typeof this.type == "string") {
        this.$message.error("请选择资质类型");
        return;
      }
      if (this.passageName == "") {
        this.$message.error("请输入您需要办理的资质名称");
        return;
      }
      if (this.username == "") {
        this.$message.error("请输入您的姓名");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phone)) {
        this.$message.error("请输入正确手机号");
        return;
      }
      let data = {
        type: this.type,
        channel: 10,
        username: this.username,
        phone: this.phone,
        requirements: this.passageName,
        source_url: source_url,
      };
      if (this.$mobile) {
        data.channel = 20;
      }
      this.$api.applyOffer(data).then((res) => {
        this.$message.success("提交成功，稍后专业顾问将为你答疑解惑");
        this.type = "请选择资质类型";
        this.passageName = "";
        this.username = "";
        this.phone = "";
      });
    },
    getPassagetype() {
      this.$api.applyCategory().then((res) => {
        console.log(res);
        this.passagetype = res.data;
      });
    },
    initHistory() {
      var historySwiper = new Swiper(".history-container", {
        loop: true,
        direction: "vertical",
        autoplay: "1000",
      });
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min)) + min;
    },
  },
  created() {
    // passageAmount
    let amount = localStorage.getItem("passageAmount");
    if (!amount) {
      let timer = new Date().getTime();
      let today = this.random(10, 99);
      let total = this.random(30000, 35000);
      let obj = {
        time: timer,
        total: total,
        today: today,
      };
      this.pAmount = obj;
      localStorage.setItem("passageAmount", JSON.stringify(obj));
      console.log("null");
    } else {
      let obj = JSON.parse(amount);
      let timer = new Date().getTime();
      let sub = Math.floor(timer - obj.time);
      var days = Math.floor(sub / (24 * 3600 * 1000));
      if (days > 0) {
        let c = this.random(10, 99);
        obj.today = c;
        obj.total = obj.total + c;
        obj.time = timer;
        localStorage.setItem("passageAmount", JSON.stringify(obj));
      }
      this.pAmount = obj;
    }
    this.getPassagetype();
  },
  mounted() {
    this.initHistory();
  },
};
</script>

<style lang="scss">
.passage {
  width: 100%;
  // position: relative;
  // transform: translateY(-30%);
  // background: #ffffff;
  z-index: 9;
  // border: 1px solid #eeeeee;
  // border-image: linear-gradient(#eeeeee, #ffffff) 0 60;
  // border-left: 0;
  // border-right: 0;
  background-image: url(../../assets/image/serve/tongdao-bg.png);
  margin-top: 50px;
  
  .main-title {
    padding: 40px 0 30px 0;
  }

  .m-passage-form {
    display: none;
  }

  .passage-form {
    @include flex-center();

    .ant-select {
      border: none;
    }

    .ant-input-group.ant-input-group-compact
      > .ant-select:first-child
      > .ant-select-selection {
      border-radius: 0;
      background-color: #f6f9fc;
    }

    .ant-input {
      text-indent: 10px;
      height: 36px;
      border-radius: 0;
      background-color: #f6f9fc;
    }

    .ant-btn {
      width: 147px;
      height: 36px;
      border-radius: 0;
    }
  }

  .passage-amount {
    @include flex-center();

    p {
      @include flex-center();
      font-size: 14px;
      color: $descColor;
      margin-right: 60px;

      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    span {
      font-size: 24px;
      font-weight: bold;
      // color: $primary-color;
    }

    .history {
      height: 40px;
      @include flex-start();

      h3 {
        font-size: 14px;
        color: $descColor;
        line-height: 40px;
      }

      .history-container {
        height: 100%;

        .swiper-slide {
          line-height: 40px;
        }
      }

      span {
        font-size: 15px;
        font-weight: 400;
        // color: $primary-color;
      }
    }
  }
}
@media only screen and (max-width: 1024px) {
  .passage {
    width: 100%;
    position: relative;
    transform: translateY(-30%);
    background: #ffffff;
    z-index: 9;
    border: 1px solid #eeeeee;
    border-image: linear-gradient(#eeeeee, #ffffff) 0 60;
    border-bottom: 0;

    .main-title {
      padding: 40px 0 30px 0;
    }

    

    .passage-form {
      padding: 0 10px;
      @include flex-center();

      .ant-select {
        border: none;
      }

      .ant-input-group.ant-input-group-compact
        > .ant-select:first-child
        > .ant-select-selection {
        border-radius: 0;
        background-color: #f6f9fc;
      }

      .ant-input {
        text-indent: 10px;
        height: 36px;
        border-radius: 0;
        background-color: #f6f9fc;
      }

      .ant-btn {
        width: 147px;
        height: 36px;
        border-radius: 0;
      }
    }

    .passage-amount {
      padding: 0 10px;
      @include flex-center();

      p {
        @include flex-center();
        font-size: 14px;
        color: $descColor;
        margin-right: 20px;

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }

      span {
        font-size: 24px;
        font-weight: bold;
        color: $primary-color;
      }

      .history {
        height: 40px;
        @include flex-start();

        h3 {
          font-size: 14px;
          color: $descColor;
          line-height: 40px;
        }

        .history-container {
          height: 100%;

          .swiper-slide {
            line-height: 40px;
          }
        }

        span {
          font-size: 15px;
          font-weight: 400;
          color: $primary-color;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .ant-select-dropdown-menu-item {
    font-size: 18px;
    line-height: 60px;
  }

  .passage {
    width: 100%;
    position: relative;
    transform: translateY(-100px);
    background: #ffffff;
    z-index: 9;
    border: 1px solid #eeeeee;
    border-image: linear-gradient(#eeeeee, #ffffff) 0 60;
    border-bottom: 0;

    .main-title {
      padding: 40px 0 30px 0;
    }

    .m-passage-form {
      display: block;

      .ant-select {
        border: none;
      }

      .ant-select-focused {
        box-shadow: none;
      }

      .ant-input-group.ant-input-group-compact
        > .ant-select:first-child
        > .ant-select-selection {
        border-radius: 0;
        background-color: #ffffff;
        height: 60px;
        border: none;
        border-bottom: 1px solid #f6f9fc;
        box-shadow: none;
      }

      .ant-select-selection__rendered {
        line-height: 60px;
        font-size: 20px;
        color: #9ca0b7;
      }

      .ant-input {
        margin-top: 10px;
        text-indent: 10px;
        height: 60px;
        border-radius: 0;
        background-color: #ffffff;
        border: none;
        font-size: 18px;
        border-bottom: 1px solid #f6f9fc;
      }

      .ant-input:focus {
        box-shadow: none;
      }

      .ant-btn {
        display: block;
        margin: 60px auto 0;
        width: 90%;
        height: 80px;
      }
    }

    .passage-form {
      display: none;
    }

    .passage-amount {
      margin-top: 0;
      flex-wrap: wrap;

      p {
        width: 60%;
        font-size: 20px;
        margin-right: 0;
        justify-content: flex-start;

        &:nth-child(1) {
          width: 40%;
        }

        &:nth-child(2) {
          justify-content: flex-end;
        }
      }

      span {
        font-size: 30px;
      }

      .history {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        @include flex-start();

        h3 {
          width: 22%;
          font-size: 20px;
        }

        .history-container {
          width: 78%;
          height: 100%;

          .swiper-slide {
            @include ellipsis();
            font-size: 24px;
            color: $primary-color;
            line-height: 40px;
          }
        }

        span {
          font-size: 15px;
          font-weight: 400;
          color: $primary-color;
        }
      }
    }
  }
}
@media only screen and (max-width: 481px) {
  .ant-select-dropdown-menu-item {
    font-size: 16px;
    line-height: 40px;
  }
	
  .passage {
    padding: 0 16px;
    transform: translateY(-60px);
	// margin-top: 100px;
    .main-title {
      padding: 40px 0 30px 0;
    }

    .m-passage-form {
      padding: 0;

      .ant-input-group.ant-input-group-compact
        > .ant-select:first-child
        > .ant-select-selection {
        height: 40px;
      }

      .ant-select-selection__rendered {
        line-height: 40px;
        font-size: 16px;
      }

      .ant-input {
        height: 40px;
        font-size: 16px;
      }

      .ant-input:focus {
        box-shadow: none;
      }

      .ant-btn {
        margin: 30px auto 0;
        height: 50px;
      }
    }

    .passage-amount {
      padding: 0;
      margin-top: 0;
      flex-wrap: wrap;

      p {
        font-size: 16px;
      }

      span {
        font-size: 20px;
      }

      .history {
        margin-top: 20px;
        width: 100%;
        height: 40px;
        @include flex-start();

        h3 {
          width: 25%;
          font-size: 16px;
        }

        .history-container {
          width: 100%;
          height: 100%;

          .swiper-slide {
            @include ellipsis();
            font-size: 16px;
            color: $primary-color;
            line-height: 40px;
          }
        }

        span {
          font-size: 15px;
          font-weight: 400;
          color: $primary-color;
        }
      }
    }
  }
  .passage-t{
	  margin-top: 100px;
  }
}
</style>

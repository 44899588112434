<template>
<div class="choose-mian bg-cent" :class="ifcolor ? 'choose-mian-new' : '' " :style="{backgroundImage: ifcolor ? 'url(' + require('@/assets/image/serve/bg-video.png') + ')' : '' }">
<div class="container"> 
  <div class="choose">
    <div class="main-title" :class="ifcolor ? 'main-title-new' : '' ">
      <h1>建筑资质办理为什么选择优普道</h1>
    </div>
    <div class="choose-main">
      <div class="choose-menu" :class=" ifcolor ? 'choose-menu-show' : '' ">
        <template v-for="(item, index) in chooseMenu">
          <div
            class="item"
            :key="index"
            @mouseenter="changeChooseMenu(index)"
            :class="{ active: chooseActive == index }"
          >
            <h3>{{ item.name }}</h3>
            <p>{{ item.sname }}</p>
          </div>
        </template>
      </div>
      <div class="choose-box" :class=" ifcolor ? 'choose-box-new' : ''">
        <div
          class="choose-content price"
          v-if="chooseActive == 0"
          :style="{
            backgroundImage: ifcolor ?  'url(' +  require('@/assets/image/serve/choosea1.png') + ')' : 'url(' +  require('@/assets/image/serve/choose1.png') + ')' ,
            backgroundSize: 'cover',
          }"
        >
          <p>
            优普道拥有建筑产业专属市场观察团队，以建筑行
            业大环境为前提，结合国家政策，为客户提供最合理的
            报价及服务，客户的满意是第一需求。
          </p>
          <p>
            一次收费终身受用，当企业发展到一定程度需要对
            资质进行升级的时候，会提供免费的咨询服务，为建企 的发展保驾护航
          </p>
        </div>
        <div
          class="choose-content talent"
          v-if="chooseActive == 1"
          :style="{
            backgroundImage: ifcolor ?  'url(' + require('@/assets/image/serve/choosea4.png') + ')' : 'url(' + require('@/assets/image/serve/choose4.png') + ')',
            backgroundSize: 'cover',
          }"
        >
          <div class="info">
            <div class="title">建筑人才储备中心</div>
            <div class="desc mt-3 pr-1">
              猎聘项目自启动以来，成功为建工企业招募和推选数万高品质人才。
              我们以时间周期短、专业针对性强、保密性高备受客户好评和信赖，
              更是贴合行业飞快发展的阶段需求。我们以负责的态度，优质的服务，品牌的实力，始终
              专注于建工行业，不断拓宽我们的服务维度。
            </div>
          </div>
          <div class="img-box">
            <!-- <img src="../../assets/image/study/1.png" alt=""> -->
          </div>
        </div>
        <div
          class="choose-content talent bg-cent"
          v-if="chooseActive == 2"
          :style="{
            backgroundImage: ifcolor ?  'url(' + require('@/assets/image/serve/choosea3.png') + ')' : 'url(' + require('@/assets/image/serve/choose3.png') + ')'
          }"
        >
     <!--     <div class="info">
            <div class="title">流程明确、办理周期短</div>
            <div class="desc mt-3 pr-1">
              资质办理流程非常明确，从建企提出资质需求、配备资质所需人员、整理并提交申报资料和最后取得企业资质。只需四个流程，一步到位，无需建企额外投入时间，所有准备的申报资料都由整个专业审计团队一手承办，极速下证。。
            </div>
          </div>
          <div class="img-box"></div> -->
        </div>
        <div
          class="choose-content process"
          v-if="chooseActive == 3"
          :style="{
            backgroundImage: ifcolor ?  'url(' + require('@/assets/image/serve/choosea2.png') + ')' : 'url(' + require('@/assets/image/serve/choose2.png') + ')',
            backgroundSize: 'cover',	
          }"
        >
         <div class="title">
            专属建筑资质服务团队平均5年以上建筑资质工作经验，专属顾问对应一个客户的服务模式，保
            证为每一位客户提供高质量、专业化的建筑资质代办服务
          </div>
          <div class="list mt-3">
            <template v-for="(item, index) in person">
              <div class="item" :key="index">
                <div class="img-box">
                  <img :src="item.head_temp" alt="" />
                </div>
                <h3 class="name mt-1">{{ item.name }}</h3>
                <p class="desc">{{ item.honor + " " + item.intro }}</p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <div class="m-choose-main">
      <div class="choose-container swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div
              class="choose-content price"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/image/092.png') + ')',
                backgroundSize: 'cover',
              }"
            >
              <div class="title">
                <p class="name">价格公开透明</p>
                <p class="sname">The price is open and transparent</p>
              </div>
              <p class="desc mt-3">
                优普道拥有建筑产业专属市场观察团队，以建筑行
                业大环境为前提，结合国家政策，为客户提供最合理的
                报价及服务，客户的满意是第一需求。
              </p>
              <p class="desc mt-1">
                一次收费终身受用，当企业发展到一定程度需要对
                资质进行升级的时候，会提供免费的咨询服务，为建企 的发展保驾护航
              </p>
            </div>
          </div>
          <div class="swiper-slide">
            <div
              class="choose-content talent"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/image/090.png') + ')',
                backgroundSize: '200%',
              }"
            >
              <div class="info">
                <div class="title">
                  <p class="name">企业专属人才库</p>
                  <p class="sname">Enterprise exclusive talent pool</p>
                </div>
                <div class="desc mt-3 pr-1">
                  猎聘项目自启动以来，成功为建工企业招募和推选数万高品质人才。
                  我们以时间周期短、专业针对性强、保密性高备受客户好评和信赖，
                  更是贴合行业飞快发展的阶段需求。我们以负责的态度，优质的服务，品牌的实力，始终
                  专注于建工行业，不断拓宽我们的服务维度。
                </div>
              </div>
              <div class="img-box">
                <!-- <img src="../../assets/image/study/1.png" alt=""> -->
              </div>
            </div>
          </div>
          <div class="swiper-slide">
            <div
              class="choose-content processing"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/image/093.png') + ')',
                backgroundSize: 'cover',
              }"
            >
              <div class="info">
                <div class="title">
                  <p class="name">流程明确、办理周期短</p>
                  <p class="sname">Clear process and short processing cycle</p>
                </div>
                <div class="desc mt-3 pr-1">
                  资质办理流程非常明确，从建企提出资质需求、配备资质所需人员、整理并提交申报资料和最后取得企业资质。只需四个流程，一步到位，无需建企额外投入时间，所有准备的申报资料都由整个专业审计团队一手承办，极速下证。。
                </div>
              </div>
              <div class="img-box"></div>
            </div>
          </div>
          <div class="swiper-slide">
            <div
              class="choose-content professional"
              :style="{
                backgroundImage:
                  'url(' + require('@/assets/image/091.png') + ')',
                backgroundSize: 'cover',
              }"
            >
              <!-- <div class="title">
								<p class="name">专业顾问团队</p>
								<p class="sname">Professional consultant team</p>
							</div> -->
              <div class="title">
                专属建筑资质服务团队平均5年以上建筑资质工作经验，专属顾问对应一个客户的服务模式，保
                证为每一位客户提供高质量、专业化的建筑资质代办服务
              </div>
              <div class="list mt-1">
                <template v-for="(item, index) in person">
                  <div class="item" :key="index">
                    <div class="img-box">
                      <img :src="item.head_temp" alt="" />
                    </div>
                    <h3 class="name mt-1">{{ item.name }}</h3>
                    <p class="desc">{{ item.honor + " " + item.intro }}</p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
export default {
  props:{
	ifcolor:{
		type:String,
		default:''
	}
  },
  data() {
    return {
      chooseActive: 0,
      chooseMenu: [
        {
          name: "价格公开透明",
          sname: "The price is open and transparent",
        },
        {
          name: "企业专属人才库",
          sname: "Enterprise exclusive talent pool",
        },
        {
          name: "流程明确、办理周期短",
          sname: "Clear process and short processing cycle",
        },
        {
          name: "专业顾问团队",
          sname: "Professional consultant team",
        },
      ],
      person: [
        {
          name: "王佩佩",
          img: require("@/assets/image/design/01.jpg"),
          desc: "资质办理专家 从业6年",
        },
        {
          name: "谢冰仪",
          img: require("@/assets/image/design/02.jpg"),
          desc: "资质办理顾问 从业4年",
        },
        {
          name: "邓秀红",
          img: require("@/assets/image/design/03.jpg"),
          desc: "资质办理顾问 从业4年",
        },
      ],
    };
  },
  methods: {
    getCounselorList() {
      this.$api.getCounselorList().then((res) => {
        this.person = res.data.list;
      });
    },
    initChooseSwiper() {
      var chooseSwiper = new Swiper(".choose-container", {
        autoplay: 2000,
        pagination: ".swiper-pagination",
      });
    },
    changeChooseMenu(index) {
      this.chooseActive = index;
    },
  },
  created() {
    this.getCounselorList();
  },
  mounted() {
    this.initChooseSwiper();
  },
};
</script>

<style lang="scss" scoped >

	.choose-mian{
		// background-image: url(../../assets/image/serve/bg-video.png);
		background-color: #FAFAFB;
		padding: 90px 0;
	}
	.choose-mian .main-title{
		padding-top: 0;
		color: #FFFFFF;
		h1{
			font-size: 36px;
			font-family: Source Han Sans CN;
			font-weight: bold;
		}
	}
	.choose-mian .main-title-new{
		h1{
			color: #FFFFFF;
		}
	}
.choose-main {
  @include flex-start();

  .choose-menu {
    width: 32%;
	background: #FFFFFF;
    .item {
      padding: 30px;
      cursor: pointer;

      h3 {
        font-size: 18px;
        font-weight: 500;
        color: #383d41;
      }

      p {
        font-size: 12px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #9ca0b7;
      }

      &:hover {
        background: #f9f9fb;

        h3 {
          color: $primary-color;
        }

        p {
          color: #a9c8ff;
        }
      }
    }

    .active {
      background: #f9f9fb;

      h3 {
        color: $primary-color;
      }

      p {
        color: #a9c8ff;
      }
    }
  }
  .choose-menu-show{
	  background: rgba(255,255,255,0.1);
	  .item {
	    padding: 30px;
	    cursor: pointer;
	    border: 1px solid rgba(255,255,255,0.2);
		border-left: 1px solid transparent;
	    h3 {
	      color: #FFFFFF;
	    }
	  
	    p {
	      color: #9CA0B7;
	    }
	  
	    &:hover {
	  
	      h3 {
			color: #FFB820;
	      }
	  
	      p {
	        color: #FFB820;
	      }
	    }
	  }
	  
	  .active {
	    background: transparent !important;

	    h3 {
	      color: #FFB820;
	    }
	  
	    p {
	      color: #FFB820;
	    }
	  }
  }
  .choose-box {
    width: 68%;
    background: #f9f9fb;
	color: $fontColor;
    .price {
      @include flex-between(column);
      align-items: flex-start;
      padding: 8% 0 8% 8%;
      height: 100%;

      p {
        width: 50%;
        font-size: 16px;
      
        line-height: 26px;
      }
    }

    .talent {
      padding: 0 50px;
      @include flex-center();
      height: 100%;

      .info {
        width: 50%;
		
        .title {
          font-size: 20px;
         

          &::after {
            content: "";
            display: block;
            width: 51px;
            height: 3px;
            background: #2070ff;
            margin-top: 20px;
          }
        }

        .desc {
          font-size: 16px;
          
          line-height: 26px;
        }
      }

      .img-box {
        width: 50%;

        img {
          max-width: 100%;
        }
      }
    }

    .process {
      padding: 30px 40px;
      height: 100%;

      .title {
        font-size: 16px;
      
        line-height: 24px;
      }

      .list {
        @include flex-around();

        .item {
          width: 22%;

          .img-box {
            max-height: 219px;
            background: #ffffff;
            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .name {
            text-align: center;
            font-size: 16px;
            color: #383d41;
          }

          .desc {
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            color: #9ca0b7;
          }
        }
      }
    }
  }
  .choose-box-new{
	  color: #FFFFFF;
	  .talent .info .title::after{
		      content: "";
		      display: block;
		      width: 51px;
		      height: 3px;
		      background: #FFB820;
		      margin-top: 20px;
	  }
  }
}

.m-choose-main {
  display: none;
  height: 420px;

  .choose-container {
    height: 100%;

    .swiper-pagination-bullet {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border: 1px solid #a4a7bc;
      border-radius: 50%;
    }

    .swiper-pagination-bullet-active {
      background: #9ca0b7;
    }

    .choose-content {
      height: 362px;
      padding: 20px;

      .title {
        margin-top: 40px;
        font-size: 18px;

        .name {
          font-size: 24px;
          font-weight: bold;
          color: #2070ff;
        }

        .sname {
          font-size: 12px;
          color: #2070ff;
          opacity: 0.37;
        }
      }

      .desc {
        font-size: 18px;
        color: #383d41;
        line-height: 30px;
      }
    }

    .price {
      .desc {
        width: 80%;
      }
    }

    .talent {
      background-position: left center;

      .desc {
        width: 80%;
      }
    }

    .processing {
      .desc {
        width: 80%;
      }
    }

    .professional {
      .title {
        margin-top: 0px;
      }

      .list {
        @include flex-around();

        .item {
          width: 22%;

          .img-box {
            max-height: 200px;
            background: #ffffff;
            box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
            overflow: hidden;

            img {
              width: 100%;
            }
          }

          .name {
            text-align: center;
            font-size: 16px;
            color: #383d41;
          }

          .desc {
            display: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .choose-main {
    .choose-box {
      width: 68%;
      background: #f9f9fb;

      .price {
        p {
          width: 70%;
        }
      }

      .talent {
        padding: 0 50px;
        @include flex-center();
        height: 100%;
        .info {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .choose-main {
    display: none;
  }

  .m-choose-main {
    display: block;
  }
}
@media only screen and (max-width: 481px) {
   .choose-mian{
	   padding: 30px 0;
   }
  .m-choose-main {
    height: 300px;
	padding: 30px 0 ;
    .choose-container {
      height: 100%;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
      }
      .choose-content {
        height: 250px;
        padding: 20px;

        .title {
          margin-top: 0px;
          font-size: 12px;

          .name {
            font-size: 18px;
            font-weight: bold;
            color: #2070ff;
          }

          .sname {
            font-size: 8px;
            color: #2070ff;
            opacity: 0.37;
          }
        }

        .desc {
          margin-top: 16px;
          font-size: 12px;
          color: #383d41;
          line-height: 19px;
        }
      }

      .price {
        .desc {
          width: 90%;
        }
      }

      .talent {
        background-position: left center;

        .desc {
          width: 90%;
        }
      }

      .processing {
        .desc {
          width: 90%;
        }
      }

      .professional {
        .title {
          margin-top: 0px;
        }

        .list {
          @include flex-around();

          .item {
            width: 22%;

            .img-box {
              max-height: 200px;
              background: #ffffff;
              box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.1);
              overflow: hidden;

              img {
                width: 100%;
              }
            }

            .name {
              text-align: center;
              font-size: 16px;
              color: #383d41;
            }

            .desc {
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              color: #9ca0b7;
            }
          }
        }
      }
    }
  }
}


.choose-mian-new .choose-box .process .list .item {
		.name{
			color: #FFFFFF;
		}
		.desc{
			color: #FFFFFF;
		}
}
</style>
